import { Outlet, Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { PartnerProvider } from '@/context/Partner/';
import { GeolocationProvider } from '@/context/Geolocation';
import LocalizationWrapper from './components/LocalizationWrapper/LocalizationWrapper';

const Main = lazy(() => import('./pages/Main'));
const Terms = lazy(() => import('./pages/Terms'));
const NewPassword = lazy(() => import('./pages/NewPassword'));

const ContextWrapper = () => {
  return (
    <GeolocationProvider>
      <PartnerProvider>
        <LocalizationWrapper>
          <Outlet />
        </LocalizationWrapper>
      </PartnerProvider>
    </GeolocationProvider>
  );
};

function App() {
  // useEffect(() => {
  //   try {
  //     const script = window.document.createElement('script');
  //     script.src = `//js.hs-scripts.com/${process.env.REACT_APP_HS_PORTAL_ID}.js?businessUnitId=${process.env.REACT_APP_HS_BUSINESS_UNIT_ID}`;
  //     script.async = true;
  //     script.defer = true;
  //     script.type = 'text/javascript';
  //     script.id = 'hs-script-loader';
  //     window.document.body.appendChild(script);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<ContextWrapper />}>
          <Route path="/" element={<Main />} />
        </Route>
        <Route path="/new-password/:token" element={<ContextWrapper />}>
          <Route path="/new-password/:token" element={<NewPassword />} />
        </Route>
        <Route path="/acceptance-terms/:vcode" element={<ContextWrapper />}>
          <Route path="/acceptance-terms/:vcode" element={<Terms />} />
        </Route>
        <Route path="/:urlPath" element={<ContextWrapper />}>
          <Route path="/:urlPath" element={<Main />} />
        </Route>
        <Route path="*" element={<ContextWrapper />}>
          <Route path="*" element={<Main />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
export default App;
