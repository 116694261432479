import {
  FC,
  createContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { IGeolocationContext, IGeolocation } from './Geolocation.types';
import axios from '../../utils/Axios';

export const GeolocationContext = createContext<IGeolocationContext | null>(
  {} as IGeolocationContext
);

export const GeolocationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [geolocation, setGeolocation] = useState<IGeolocation | null>(null);
  const [isOman, setIsOman] = useState(false);

  const fetchGeolocation = useCallback(async () => {
    try {
      const { data } = await axios.get<IGeolocation>(
        'https://get.geojs.io/v1/ip/geo.json'
      );

      setGeolocation(data ?? null);
      setIsOman(data.country_code === 'OM');
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    fetchGeolocation();
  }, [fetchGeolocation]);

  return (
    <GeolocationContext.Provider value={{ geolocation, isOman, setIsOman }}>
      {children}
    </GeolocationContext.Provider>
  );
};
