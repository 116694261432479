import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  palette: {
    common: {
      main: '#D69B43',
      mainDark: '#484541',
      mainLight: '#FFEFD7',
      primaryText: '#383943',
      secundaryText: '#68717A',
      subText: '#9FA2B4',
      greyBorder: '#DFE0EB',
      pageBackground: '#F7F8FC',
      menu: '#363740',
    },
    white: '#FFFFFF',
    darkGrey: '#252733',
    grey: '#585961',
    green: '#28A745',
    red: '#DC3545',
    yellow: '#CFAA28',
    primaryGradient: 'linear-gradient(180deg, #D69B43 0%, #F5B656 100%)',
  },
};
