import { useContext } from 'react';
import { PartnerContext } from '../../context/Partner/Partner.context';
import { IPartner } from '../../context/Partner/Partner.types';

export const usePartnerData = (): IPartner | null => {
  const context = useContext(PartnerContext);
  return context;
};

export default usePartnerData;
