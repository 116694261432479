import { FormattedMessage } from 'react-intl';

const CONSTANTS = {
  DEV: process.env.REACT_APP_ENV_MODE === 'development',

  GOOGLE_RECAPTCHA_SITEKEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY,

  BACKEND_URL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000',

  LANGUAGES: ['de', 'en', 'es', 'fr', 'it', 'pt'],

  ACCORDION_DATA: [
    {
      title: <FormattedMessage id="accordion.title" />,
      info: <FormattedMessage id="accordion.info" />,
    },
    {
      title: <FormattedMessage id="accordion.title2" />,
      info: <FormattedMessage id="accordion.info2" />,
    },
    {
      title: <FormattedMessage id="accordion.title3" />,
      info: <FormattedMessage id="accordion.info3" />,
    },
    {
      title: <FormattedMessage id="accordion.title4" />,
      info: <FormattedMessage id="accordion.info4" />,
    },
    {
      title: <FormattedMessage id="accordion.title5" />,
      info: <FormattedMessage id="accordion.info5" />,
    },
    {
      title: <FormattedMessage id="accordion.title6" />,
      info: <FormattedMessage id="accordion.info6" />,
    },
    {
      title: <FormattedMessage id="accordion.title7" />,
      info: <FormattedMessage id="accordion.info7" />,
    },
    {
      title: <FormattedMessage id="accordion.title8" />,
      info: <FormattedMessage id="accordion.info8" />,
    },
    {
      title: <FormattedMessage id="accordion.title9" />,
      info: <FormattedMessage id="accordion.info9" />,
    },
    {
      title: <FormattedMessage id="accordion.title10" />,
      info: <FormattedMessage id="accordion.info10" />,
    },
    {
      title: <FormattedMessage id="accordion.title11" />,
      info: <FormattedMessage id="accordion.info11" />,
    },
    {
      title: <FormattedMessage id="accordion.title12" />,
      info: <FormattedMessage id="accordion.info12" />,
    },
    {
      title: <FormattedMessage id="accordion.title13" />,
      info: <FormattedMessage id="accordion.info13" />,
    },
    {
      title: <FormattedMessage id="accordion.title14" />,
      info: <FormattedMessage id="accordion.info14" />,
    },
    {
      title: <FormattedMessage id="accordion.title15" />,
      info: <FormattedMessage id="accordion.info15" />,
    },
  ],

  CARD_DATA: {
    transplantes: {
      iconPath: '/img/icons/transplantes.svg',
      alt: 'transplantes',
      title: (
        <FormattedMessage
          id="card.transplant.title"
          defaultMessage="Transplant"
        />
      ) as unknown as string,
      text: (
        <FormattedMessage
          id="card.transplant.text"
          defaultMessage="Transplant"
        />
      ) as unknown as string,
    },
    medicos: {
      iconPath: '/img/icons/medicos.svg',
      alt: 'medicos',
      title: (
        <FormattedMessage id="card.doctor.title" defaultMessage="Transplant" />
      ) as unknown as string,
      text: (
        <FormattedMessage id="card.doctor.text" defaultMessage="Transplant" />
      ) as unknown as string,
    },
    rapidez: {
      iconPath: '/img/icons/rapidez.svg',
      alt: 'rapidez',
      title: (
        <FormattedMessage id="card.speed.title" defaultMessage="Transplant" />
      ) as unknown as string,
      text: (
        <FormattedMessage id="card.speed.text" defaultMessage="Transplant" />
      ) as unknown as string,
    },
    resultados: {
      iconPath: '/img/icons/resultados.svg',
      alt: 'resultados',
      title: (
        <FormattedMessage id="card.results.title" defaultMessage="Transplant" />
      ) as unknown as string,
      text: (
        <FormattedMessage id="card.results.text" defaultMessage="Transplant" />
      ) as unknown as string,
    },
    financiamento: {
      iconPath: '/img/icons/financiamento.svg',
      alt: 'financiamento',
      title: (
        <FormattedMessage
          id="card.financing.title"
          defaultMessage="Transplant"
        />
      ) as unknown as string,
      text: (
        <FormattedMessage
          id="card.financing.text"
          defaultMessage="Transplant"
        />
      ) as unknown as string,
    },
    instalacoes: {
      iconPath: '/img/icons/instalacoes.svg',
      alt: 'instalacoes',
      title: (
        <FormattedMessage
          id="card.installations.title"
          defaultMessage="Transplant"
        />
      ) as unknown as string,
      text: (
        <FormattedMessage
          id="card.installations.text"
          defaultMessage="Transplant"
        />
      ) as unknown as string,
      title_om: (
        <FormattedMessage
          id="card.installations_om.title"
          defaultMessage="Transplant"
        />
      ) as unknown as string,
      text_om: (
        <FormattedMessage
          id="card.installations_om.text"
          defaultMessage="Transplant"
        />
      ) as unknown as string,
    },
    cabelo: {
      iconPath: '/img/icons/icon-cabelo.svg',
      alt: 'cabelo',
      title: (
        <FormattedMessage id="card.hair.title" defaultMessage="Transplant" />
      ) as unknown as string,
      text: (
        <FormattedMessage id="card.hair.text" defaultMessage="Transplant" />
      ) as unknown as string,
    },
    barba: {
      iconPath: '/img/icons/icon-barba.svg',
      alt: 'barba',
      title: (
        <FormattedMessage id="card.beard.title" defaultMessage="Transplant" />
      ) as unknown as string,
      text: (
        <FormattedMessage id="card.beard.text" defaultMessage="Transplant" />
      ) as unknown as string,
    },
    sobrancelhas: {
      iconPath: '/img/icons/icon-sobrancelhas.svg',
      alt: 'sobrancelhas',
      title: (
        <FormattedMessage
          id="card.eyebrows.title"
          defaultMessage="Transplant"
        />
      ) as unknown as string,
      text: (
        <FormattedMessage id="card.eyebrows.text" defaultMessage="" />
      ) as unknown as string,
    },
  },

  REVIEWS_DATA: [
    {
      imgPath: '/img/reviews/avatar.png',
      name: (
        <FormattedMessage id="reviewer.name1" defaultMessage="Ana Ornelas" />
      ) as unknown as string,
      comment: (
        <FormattedMessage id="review.comment1" defaultMessage="" />
      ) as unknown as string,
      stars: 5,
    },
    {
      imgPath: '/img/reviews/avatar.png',
      name: (
        <FormattedMessage
          id="reviewer.name2"
          defaultMessage="Claudio Sandner"
        />
      ) as unknown as string,
      comment: (
        <FormattedMessage id="review.comment2" defaultMessage="" />
      ) as unknown as string,
      stars: 5,
    },
    {
      imgPath: '/img/reviews/avatar.png',
      name: (
        <FormattedMessage
          id="reviewer.name3"
          defaultMessage="Gabriela Soares"
        />
      ) as unknown as string,
      comment: (
        <FormattedMessage
          id="review.comment3"
          defaultMessage="Claudio Sandner"
        />
      ) as unknown as string,
      stars: 5,
    },
    {
      imgPath: '/img/reviews/avatar.png',
      name: (
        <FormattedMessage
          id="reviewer.name4"
          defaultMessage="Gustavo Pereira"
        />
      ) as unknown as string,
      comment: (
        <FormattedMessage id="review.comment4" defaultMessage="*" />
      ) as unknown as string,
      stars: 5,
    },
  ],
};

export default Object.freeze(CONSTANTS);
