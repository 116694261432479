import {
  FC,
  createContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { IPartner } from './Partner.types';
import axios from '../../utils/Axios';

export const PartnerContext = createContext<IPartner | null>({} as IPartner);

export const PartnerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [partner, setPartner] = useState<any>({ hasFetched: false } as any);

  const params = useParams();

  const fetchPartner = useCallback(async (urlPath: string | null) => {
    try {
      const { data } = await axios.get(`/partners/lp/${urlPath}`);
      setPartner({
        ...data?.payload,
        defaultLanguage: data?.payload?.partner?.businessUnit?.language,
        hasFetched: true,
      });
    } catch (e) {
      setPartner({ partner: null, hasFetched: true, defaultLanguage: 'en' });
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const urlPath = params.urlPath;
    if (urlPath) {
      fetchPartner(urlPath);
    } else {
      setPartner({ partner: null, hasFetched: true, defaultLanguage: 'en' });
    }
  }, [fetchPartner, params]);

  return (
    <PartnerContext.Provider value={partner}>
      {children}
    </PartnerContext.Provider>
  );
};
