import { FC, ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import IntlContext from '@/context/Intl/Intl.context';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import { usePartnerData } from '@/hooks/PartnerData';
import constants from '@/constants';
import { useGeolocation } from '@/hooks/Geolocation';

const languages = constants.LANGUAGES;

export const loadMessages = (locale: string): any => {
  switch (locale) {
    case 'de':
      return import('../../lang/de.json');
    case 'en':
      return import('../../lang/en.json');
    case 'es':
      return import('../../lang/es.json');
    case 'fr':
      return import('../../lang/fr.json');
    case 'it':
      return import('../../lang/it.json');
    default:
      return import('../../lang/pt.json');
  }
};

const checkLang = (lang: string, langs: string[]) => {
  return langs.includes(lang);
};

const LocalizationWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const geolocationData = useGeolocation();
  const partnerData = usePartnerData();

  const [lang, setLang] = useState<string | void>(() => {
    const langInit =
      searchParams.get('lang') || localStorage.getItem('lang') || '';
    return checkLang(langInit, languages) ? langInit : '';
  });

  const [messages, setMessages] = useState<
    | Record<string, string>
    | Record<string, MessageFormatElement[]>
    | undefined
    | null
  >(null);

  useEffect(() => {
    if (!partnerData?.hasFetched) return;

    const isOman =
      partnerData?.partner?.businessUnit?.code === 'OM' ||
      (geolocationData && geolocationData?.isOman);

    geolocationData?.setIsOman(isOman ?? false);

    const defaultBuLang = partnerData?.defaultLanguage;

    const initialLanguage = lang || defaultBuLang;

    const validLang = isOman
      ? 'en'
      : checkLang(initialLanguage as string, languages)
      ? initialLanguage
      : 'en';

    loadMessages(validLang as string).then(setMessages);
    localStorage.setItem('lang', validLang as string);

    searchParams.set('lang', validLang as string);
    setSearchParams(searchParams);

    setLang(validLang);
  }, [
    lang,
    searchParams,
    setSearchParams,
    partnerData?.defaultLanguage,
    partnerData?.hasFetched,
    partnerData?.partner?.businessUnit?.code,
    geolocationData,
  ]);

  return messages ? (
    <IntlContext.Provider value={[lang as string, setLang]}>
      <IntlProvider locale={lang as string} messages={messages}>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  ) : null;
};

export default LocalizationWrapper;
