import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  --breakpoint-sm: 480px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}


h1 {
  font-weight: 700;
}

h2 {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3 { 
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4 { 
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5 { 
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}


p {
  font-size: 18px;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 20px;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

a {
  color: #333;
  transition: all 0.35s;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input {
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}


[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}


small {
  font-size: 80%;
  font-weight: 400;
}



a:focus,
a:hover {
  color: #00aeef;
  text-decoration: none;
}

.btn:hover {
  color: #fff;
}

input::placeholder {
  color: #b0b0b0 !important;
}

section {
  padding: 50px 0;
}
`;

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 0;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

/* container-fluid h-100 + row (nested div) */
export const FluidContainer = styled.div`
  display: flex;
  height: 100% !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

export default GlobalStyle;
