import { useContext } from 'react';
import { GeolocationContext } from '../../context/Geolocation/Geolocation.context';
import { IGeolocationContext } from '../../context/Geolocation/Geolocation.types';

export const useGeolocation = (): IGeolocationContext | null => {
  const context = useContext(GeolocationContext);
  return context;
};

export default useGeolocation;
